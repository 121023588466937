/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { Homepage } from "../../../components/homepage"

export const pageQuery = graphql`
  query HomeQueryX {
    file(relativePath: { eq: "krakow_airport_front.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 585, height: 439)
      }
    }
    posts: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___order_parking] }
      filter: { frontmatter: { template: { eq: "parking-post" } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            slug
            title
            isParkVia
            doNotShowOnMainPage
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 345, height: 260)
              }
            }
          }
        }
      }
    }
  }
`

const frontmatter = {
  slug: "/",
  title: "Balice Parking Lotnisko Kraków Balice",
  tagline: "Kraków, Polska",
  featuredImage: "/assets/krakow_airport_front.jpg",
  cta: {
    ctaText: "Wyszukaj parking",
    ctaLink: "#search",
  },
  contentLink: "/content/parking-balice/homepage.js",
}

const HomePage = ({ data }) => (
  <Homepage
    frontmatter={frontmatter}
    data={data}
    descriptionComponent={
      <p>
        <b>Adres:</b> Kapitana Mieczysława Medweckiego 1, 32-083 Balice
        Międzynarodowy Port Lotniczy im. Jana Pawła II Kraków-Balice - port
        lotniczy położony 11 km na zachód od centrum Krakowa, w miejscowości
        Balice. Port lotniczy jest zlokalizowany obok 8 Bazy Lotnictwa
        Transportowego w Balicach i współużytkuje z nią betonową drogę startową
        o wymiarach 2550 × 60 m.
      </p>
    }
  >
    <p>
      Jeśli planujesz lot z lotniska w Balicach, z pewnością zastanawiasz się,
      gdzie zostawić swój samochód. Parkowanie na terenie lotniska może być
      bardzo kosztowne, a miejsca parkingowe są często ograniczone. Dlatego
      wiele osób decyduje się na skorzystanie z usług prywatnych parkingów,
      które oferują niższe ceny i lepsze warunki. Sprawdź najlepsze parkingi w
      okolicy lotniska w Balicach, które warto rozważyć przed lotem. Parkingi
      lotniskowe w Krakowie Balicach to świetny sposób na zaparkowanie pojazdu
      podczas podróży. Dzięki automatycznym systemom wjazdu i wyjazdu podróżni
      mogą łatwo znaleźć swój samochód po powrocie. Parkingi oferują całodobowy
      parking strzeżony z kamerami CCTV i innymi zabezpieczeniami oraz dogodne
      opcje płatności.
    </p>
    <h2>
      <strong>
        Porównanie najlepszych parkingów w okolicy lotniska Balice
      </strong>
    </h2>
    <h3>
      <strong>
        <a href="/b1/">B1 Parking</a> i{" "}
        <a href="/b1-zadaszony">B1 Parking Zadaszony</a>
      </strong>
    </h3>
    <p>
      B1 Parking to prywatny parking, który znajduje się w bezpośrednim
      sąsiedztwie lotniska. Oferuje on w pełni ogrodzony i oświetlony teren,
      monitoring oraz 24-godzinny dostęp do samochodu. Parking oferuje różne
      warianty parkowania, w tym parkowanie zadaszone i niezadaszone. B1 Parking
      to dobra opcja dla osób, które szukają miejsca parkingowego blisko
      lotniska.
    </p>
    <h3>
      <strong>
        <a href="/oficjalny-krk/">Oficjalny Parking na lotnisku</a>
      </strong>
    </h3>
    <p>
      Oficjalny Parking na lotnisku to parking oferowany przez same lotnisko.
      Jest to wygodna opcja, ponieważ nie trzeba przemieszczać się z parkingu na
      lotnisko. Jednak ceny oferowane przez lotnisko są zwykle wyższe niż te
      oferowane przez prywatne parkingi w okolicy.
    </p>
    <h3>
      <strong>
        <a href="/balicelot24/">BaliceLot24</a>
      </strong>
    </h3>
    <p>
      Balice Lot 24 to prywatny parking, który oferuje 24-godzinny dostęp do
      samochodu, monitoring oraz w pełni ogrodzony teren. Oferuje on również
      transport na lotnisko i z powrotem, co jest wygodnym rozwiązaniem dla
      osób, które nie chcą martwić się o dojazd. Parking oferuje różne warianty
      parkowania, w tym parkowanie zadaszone i niezadaszone.
    </p>
    <h3>
      <strong>
        <a href="/krakow-king/">Kraków KING</a>
      </strong>
    </h3>
    <p>
      Kraków King to prywatny parking, który znajduje się około 3 km od
      lotniska. Oferuje on w pełni ogrodzony teren, monitoring oraz 24-godzinny
      dostęp do samochodu. Parking oferuje różne warianty parkowania, w tym
      parkowanie zadaszone i niezadaszone. Jest to dobra opcja dla osób, które
      chcą uniknąć wysokich cen parkingu na lotnisku.
    </p>
    <p>
      <strong>
        <a href="/holiday-car-park">Holiday Car Park</a>
      </strong>
    </p>
    <p>
      Holiday Car Park to prywatny parking, który oferuje 24-godzinny dostęp do
      samochodu, monitoring oraz w pełni ogrodzony teren. Oferuje on również
      transport na lotnisko i z powrotem.
    </p>
    <p>
      <strong>
        <a href="/parking-green-lotnisko-krakow">Green bis</a>
      </strong>
    </p>
    <p>
      Jesteśmy firmą, która specjalizuje się w obsłudze klientów korzystających
      z lotniska Kraków-Balice. Nasza bliska lokalizacja w stosunku do terminalu
      międzynarodowego oraz atrakcyjne ceny sprawiają, że jesteśmy uznawani za
      lidera w branży parkingowej. Parking znajduje się 3.5 km od lotniska. Nasz
      parking działa nieprzerwanie, przez całą dobę, a jego teren jest
      ogrodzony, doskonale oświetlony i stale monitorowany, co zapewnia
      maksymalne bezpieczeństwo Twojego pojazdu.
    </p>
  </Homepage>
)

export default HomePage
